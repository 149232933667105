import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
  CheckBadgeIcon,
  ClockIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { calcWarrantyTimeLeft } from '@rabbit/bizproc/react';
import { AppInfoShape, Warranty, WarrantyStatus } from '@rabbit/data/types';
import {
  Heading,
  Modal,
  Button,
  CheckBadgeRectIcon,
  VoidBadgeIcon,
} from '@rabbit/elements/shared-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import useWarrantyTypes, { TenantType } from './WarrantyTypes';

interface RecordType {
  label: string;
  value: string | Array<string> | boolean | number | undefined;
  ValueElement?: JSX.Element;
}

export default function WarrantyDetailSection({
  warranty,
  tenantType = 'defaultTenants',
  appInfo,
}: {
  tenantType: TenantType;
  warranty: Warranty;
  appInfo: AppInfoShape;
}) {
  const remainingTime = calcWarrantyTimeLeft(warranty?.endDate);
  const [showWarrantyModal, setShowWarrantyModal] = useState(false);
  const [proofOfPurchaseModal, setProofOfPurchaseModal] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const CFG_WARRANTY_INFO_URL = t('CFG_WARRANTY_INFO_URL');

  const modalSettings = {
    kind: 'generic' as const,
    settings: {
      title: t('general.warrantyInformation'),
      handleClose: () => setShowWarrantyModal(false),
    },
  };

  const record = useWarrantyTypes({ type: tenantType, warranty, appInfo });
  const active: boolean = warranty.status === WarrantyStatus.ACTIVE;
  const voided: boolean = warranty.status === WarrantyStatus.VOIDED;
  const suspended: boolean = warranty.status === WarrantyStatus.SUSPENDED;
  const expired: boolean = remainingTime?.amount
    ? remainingTime.amount < -1 || remainingTime.amount === 0
    : false;

  return (
    <div
      className={`${active && 'bg-primary-50 border-primary-800 border-2'} ${
        suspended && 'bg-canvas border-2'
      } ${expired && 'border-2 border-red-600 bg-red-50'} ${
        voided && 'border-2 border-gray-300 bg-gray-200 opacity-[0.8]'
      } ${
        !active && !suspended && !expired && !voided && 'bg-white'
      } relative flex w-full flex-col overflow-hidden rounded-[6px]`}
    >
      {!warranty && <RenderLoadingState />}
      {warranty && (
        <div className="font-nunito flex flex-col p-4">
          <div className="flex justify-between">
            <div className="flex items-center gap-4">
              <div className="h-[84px] w-[84px] rounded-lg p-3">
                <CheckBadgeRectIcon className="h-full w-full text-gray-400" />
              </div>
              <div className="font-nunito flex flex-col">
                <div className="flex gap-2 font-bold">
                  {warranty.templateTitle || '-'}
                  <div
                    className={`${active && 'bg-primary-800'} ${
                      suspended && 'bg-gray-500'
                    } ${expired && 'bg-red-600'} ${
                      voided && 'bg-gray-500'
                    } flex w-fit items-center gap-1 rounded-2xl px-2 py-1`}
                  >
                    {active && (
                      <CheckBadgeIcon className="h-4 w-4 text-white" />
                    )}
                    {suspended && <ClockIcon className="h-4 w-4 text-white" />}
                    {expired && <XCircleIcon className="h-4 w-4 text-white" />}
                    {voided && <XCircleIcon className="h-4 w-4 text-white" />}
                    <span className="text-sm font-bold text-white">
                      {suspended && t('general.pending')}
                      {active && t('general.active')}
                      {expired && t('general.expired')}
                      {voided && t('general.voided')}
                    </span>
                  </div>
                </div>
                <div className="text-base text-gray-500">
                  {tenantType === 'tableTenants'
                    ? t('general.servicePlan')
                    : t('general.warranty')}
                </div>
                <p className="text-base text-gray-900">{appInfo.name}</p>
              </div>
            </div>
            {voided && (
              <div className="absolute top-1/2 left-1/2 flex -translate-x-1/2 -translate-y-1/2">
                <VoidBadgeIcon className="h-[120px] w-[400px] -rotate-45 text-red-600 opacity-[0.3]" />
              </div>
            )}
            {remainingTime?.amount && remainingTime.amount > 0 && (
              <div className="flex items-start">
                <div className="font-nunito flex flex-col items-center font-medium">
                  <Heading kind={'h2'} color="text-primary-700">
                    {remainingTime.amount.toString()}
                  </Heading>
                  <div className="text-center">
                    <p className="text-primary-700 mt-[-8px] mb-[5px] text-[8px]">
                      {remainingTime.unit}
                    </p>
                    <p className="text-[10px]">
                      {tenantType === 'tableTenants'
                        ? t('general.servicePlan')
                        : t('general.warranty')}
                      <br />
                      {t('general.remainingl')}
                    </p>
                  </div>
                </div>
                {appInfo?.name
                  .toLowerCase()
                  .includes(String(warranty.retailerLink)?.toLowerCase()) &&
                CFG_WARRANTY_INFO_URL?.length ? (
                  <div
                    className="relative z-20 cursor-pointer"
                    onClick={() => setShowWarrantyModal(true)}
                  >
                    <InformationCircleIcon className="relative top-2 h-5 w-5 text-black" />
                  </div>
                ) : null}
              </div>
            )}
            {remainingTime && remainingTime.amount === -1 && (
              <div className="font-nunito flex flex-col items-center font-medium">
                <p className="text-primary-700 text-2xl font-normal">∞</p>
                <p className="w-[75px] text-center text-[10px]">
                  {t('general.lifetimeWarranty')}
                </p>
              </div>
            )}
            {expired && (
              <div className="font-nunito flex flex-col items-center font-medium">
                <ExclamationTriangleIcon className="h-10 w-10 text-red-600" />
                <p className="w-[75px] text-center text-[10px]">
                  {t('general.warrantyExpired')}
                </p>
              </div>
            )}
          </div>
          <div className="mt-5 grid grid-cols-2 gap-4 md:grid-cols-6 md:gap-6">
            {record.map((info) => renderLabelValue(info))}
          </div>
        </div>
      )}

      {showWarrantyModal && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={modalSettings.kind}
          settings={modalSettings.settings}
        >
          <div className="font-nunito p-5 text-sm">
            {t('general.forCertain')} {appInfo?.name}{' '}
            {t('general.productComponents')}
            <a href={CFG_WARRANTY_INFO_URL} target="_blank" rel="noreferrer">
              {t('general.clickHere')}
            </a>{' '}
            {t('general.forMoreInformation')}.
          </div>
        </Modal>
      )}
      {proofOfPurchaseModal && (
        <Modal
          kind="generic"
          settings={{
            title: 'Proof of purchase',
            handleClose: () => setProofOfPurchaseModal(false),
          }}
          className="m-auto w-[724px] rounded-md border bg-white"
        >
          <div className="px-5">
            <div className="mt-4 flex w-full gap-2">
              <Button
                kind="primary"
                onClick={() => setProofOfPurchaseModal(false)}
              >
                {t('general.ok')}
              </Button>
              <Button kind="red" onClick={() => setProofOfPurchaseModal(false)}>
                {t('general.close')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

const renderLabelValue = (info: RecordType) => (
  <div className="flex flex-col gap-1" key={info.label}>
    <div className="text-sm text-gray-500">{info.label}</div>
    {!info.ValueElement && (
      <div className="text-base text-black">{info.value}</div>
    )}
    {info.ValueElement && info.ValueElement}
  </div>
);

const RenderLoadingState = () => (
  <div className="flex flex-col gap-6 p-5">
    <div className="grid grid-cols-2 gap-5 md:grid-cols-6">
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
    </div>
    <div className="grid grid-cols-2 gap-5 md:grid-cols-6">
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
    </div>
  </div>
);
